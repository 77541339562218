<template>
  <page-layout title="Tutoruu Support" :linkDefault="`/${this.username}/dashboard`">
  <div class="-mb-6 pb-36 relative mx-4">
    <p class="my-2 text-lg md:text-xl">
      The Tutoruu Fam prioritizes YOUR well-being, first and foremost. Let us know how we can help at an anytime, we <span class="text-red-600">❤️</span> to chat! <br><br> 
      send us a message below, and we’ll shoot you a response within 24 hrs! 🌟
    </p>
    <form-input label="subject" v-model:value="subject" placeholder="e.g. i can't find my class" required @error="submitDisabled = true"  @clean="submitDisabled = false"/>
    <form-input label="message" v-model:value="message" placeholder="share more details about your concern" textArea />

    <div class="w-full px-4 flex justify-end absolute bottom-20">
        <Button type="primary" text="submit!" @click="submit()" />
    </div>
  </div>
  </page-layout>
</template>

<script >
import { useRouter } from 'vue-router';
import { mapState, mapActions } from "vuex";
import PageLayout from "../components/base/pageLayout.vue";
import Button from '../components/button.vue';
import FormInput from '../components/formInput.vue';

export default {
  components: { PageLayout, FormInput, Button },
  name: "Help",
  computed: {
    ...mapState(["username"]),
  },
  setup() {
      const router = useRouter();
      return { router }
  },
  data() {
      return {
          message: '',
          subject: '',
          submitDisabled: false,
      }
  },
  methods: {
    ...mapActions(['help_me']),
      submit() {
          if(!this.submitDisabled && this.subject != '') {
            this.help_me({message: this.message, subject: this.subject})
            this.$router.push(`/${this.username}/help-is-on-the-way`)
          }
      }
  }
};
</script>